import { React, useState, useEffect } from 'react'
import { LegacyCard, Text, Button, ButtonGroup, SkeletonBodyText, BlockStack, InlineStack, Toast } from '@shopify/polaris'
import { authInstance, getOrcaHistory, showTime } from '../helper'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// import { authInstance } from '../helper'

const Page4 = ({ handleTabChange, isPaid, trialDayLeft }) => {
  const [projectState, setProjectState] = useState(-1)
  const [orcaUrl, setOrcaUrl] = useState('')
  const [maUrl, setMaUrl] = useState('')
  const [lastUpdate, setLastUpdate] = useState(' ')
  const [openSuccessBanner, setOpenSuccessBanner] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('success')
  const { t } = useTranslation('page4')
  // const [orcaState, setOrcaState] = useState(0)
  // const [bmState, setBmState] = useState(0)
  /*
  if (projectState === 0) {
    setProjectState(2)
  }
  */

  const isPageActive = isPaid || (trialDayLeft > 0)

  const getOrcaUrl = async () => {
    const res = await authInstance.get('/get_orca_url', {})
    setOrcaUrl(res.data.orca_analytics_url)
    setMaUrl(res.data.orca_ma_url)
  }
  /*
  const getShopInfo = async () => {
    const res = await authInstance.get('/get_shop_info')
    if (res.status === 200) {
      const data = res.data
      setDomain(data.shopname)
      setCName(data.company_name)
      setCId(data.company_id_number)
      setEmail(data.email)
      setPhone(data.phone)
      setorcaNames(data.oca_names)
    }
  }
  */
  const anyncForUseEffect = async () => {
    let orcaState = 0
    let bmState = 0
    const res = await authInstance.get('/get_shop_info')
    // let res = await authInstance.get('/get_shop_info')
    /*
    orcaState
    0 = 基本資料未設定
    1 = 基本資料已設定
    2 = 自動拋轉資料到 ORCA 進行中
    3 = 自動拋轉資料到 ORCA 完成
    4 = 已送出分析請求但未完成
    5 = 分析完成

    bmState
    0 = 未建站
    1 = 已建站
    2 = 資料正確

    ProjectState
    0 = 基本資料未填 orca=0
    1 = 商品/客戶資料未上傳 orca=1
    2 = 商品/客戶資料上傳中，請等待 orca=2
    3 = 資料上傳完成，但尚未建站，或客戶/商品資料格式尚未被人工確認   orca>2, bm<2
    4 = 可進行分析(資料上傳完成、已建站、客戶/商品資料格式正確，尚未進行第一次分析) orca=3, bm=2
    5 = 資料分析中 orca=4, bm=2
    6 = 可進行分析(資料上傳完成、已建站、客戶/商品資料格式正確，至少進行過一次分析) orca=5, bm=2
    */
    if (res.data.orca_state === null) {
      setProjectState(0) // 首先判斷 基本資料填了沒
      return
    } else {
      // setOrcaState(res.data.orca_state)
      orcaState = res.data.orca_state
      if (res.data.orca_state === 0) {
        setProjectState(0)
        return
      }
    }
    if (res.data.bm_status !== null) {
      // setBmState(res.data.bm_status)
      bmState = res.data.bm_status
    }
    let tempState = 0
    if (orcaState === 1) {
      tempState = 1
    } else if (orcaState === 2) {
      tempState = 2
    } else if (orcaState > 2) {
      if (bmState < 2) {
        tempState = 3
      } else if (bmState === 2) {
        if (orcaState === 3) {
          tempState = 4
        } else if (orcaState === 4) {
          tempState = 5
        } else if (orcaState === 5) {
          tempState = 6
        }
      }
    }
    if (tempState > 3) {
      getOrcaUrl()
      handleOrcaHistory()
    }
    console.log(
      'orcaState:', orcaState,
      'bmState:', bmState,
      'tempState:', tempState
    )
    setProjectState(tempState)
  }

  useEffect(() => {
    anyncForUseEffect()
  }, [])

  const handleGotoMa = () => {
    if (maUrl === '') {
      setMessage(t('sorry'))
      setStatus('warning')
      setOpenSuccessBanner(true)
    } else {
      // window.location.href = maUrl
      window.open(maUrl, '_blank')
    }
  }

  const handleGotoOrca = () => {
    if (orcaUrl === '') {
      setMessage(t('sorry'))
      setStatus('warning')
      setOpenSuccessBanner(true)
    } else {
      // window.location.href = orcaUrl
      window.open(orcaUrl, '_blank')
    }
  }

  const [countSuccessAnalysis, setCountSuccessAnalysis] = useState('-')

  const handleOrcaHistory = async () => {
    const res = await getOrcaHistory()
    const temphis = res.data.data
    // await setHistory(res.data.data)
    if (temphis.length > 0) {
      setLastUpdate(showTime(temphis[0].edit_time))
      /*
      if (temphis[0].status === 'finish') {
        setProjectState(4)
      } else if (temphis[0].status === 'processing') {
        setProjectState(5)
      } else {
        // 這邊也許以後要加一個新狀態？例外/錯誤之類的
        setProjectState(4)
      }
      */
    } else {
      setLastUpdate(t('no_records'))
      // setProjectState(2)
    }
    // setProjectState(3)
    // 試用期間內可以免費執行 3 次數據分析
    if (trialDayLeft > 0 && temphis.length >= 0) {
      let count = 0
      const maxCount = 3
      temphis.some(his => {
        if (his.status === 'finish') {
          count++
        }
        return count === maxCount
      })
      setCountSuccessAnalysis(count.toString())
    }
  }

  const handleOrcaAnalyze = async () => {
    const res = await authInstance.get('/run_orca_analyze', {})
    setMessage(res.data.message)
    if (res.data.status === 'success') {
      setStatus('success')
    } else {
      setStatus('warning')
    }
    setOpenSuccessBanner(true)
  }

  const handleUploadToOrcaHandler = async () => {
    authInstance.get('/reports/upload-to-orca')
    setMessage(t('start_update'))
    setOpenSuccessBanner(true)
  }

  return (
    <div>
      {openSuccessBanner && <Toast
          content={message} duration={8000}
          error={status === 'warning'}
          onDismiss={() => { setOpenSuccessBanner(false) }}/>}
      {(projectState === -1) &&
        <LegacyCard.Section>
          <SkeletonBodyText lines={10}/>
        </LegacyCard.Section>
      }
      {(projectState === 0) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="center">
            <Text variant="headingSm">
              {t('basic_info_missing')}
            </Text>
            <Button onClick={() => handleTabChange(1)}>{t('fill_in_basic_information')}</Button>
          </BlockStack>
        </LegacyCard.Section>
      }
      {(projectState === 1) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="center">
            <Text variant="headingSm">
              {t('not_sync_data')}
            </Text>
            <Button onClick={() => handleTabChange(2)}>{t('sync_product')}</Button>
          </BlockStack>
        </LegacyCard.Section>
      }
      {(projectState === 2 || projectState === 3) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="start">
            <Text as="h1" variant="headingXl">
              {t('orca_building')}
            </Text>
            <BlockStack gap="2">
              <Text as="p" variant="bodyLg">
                {t('orca_process')}
              </Text>
              <Text as="p" variant="bodyLg">
                {t('re_sync_if_updated')}
              </Text>
            </BlockStack>
          </BlockStack>
            <InlineStack align="end">
              <Button onClick={handleUploadToOrcaHandler} disabled={!isPageActive}>{t('re_sync_data')}</Button>
            </InlineStack>
        </LegacyCard.Section>
      }
      {(projectState === 4) &&
        <LegacyCard.Section>
          <BlockStack gap="5" inlineAlign="start">
            <Text as="h1" variant="headingXl">
              {t('congrats')}
            </Text>
            <BlockStack gap="2" inlineAlign="start">
              <Text as="p" variant="bodyLg">
                {t('email_notify')}
              </Text>
              <Text as="p" variant="bodyLg">
                {t('re_sync_if_updated')}
              </Text>
            </BlockStack>
          </BlockStack>
          <InlineStack align="end">
            <Button onClick={handleUploadToOrcaHandler} disabled={!isPageActive}>{t('re_sync_data')}</Button>
          </InlineStack>
        </LegacyCard.Section>
      }
      {(projectState === 5) && <div>
        <LegacyCard.Section>
          <BlockStack>
            <Text as="h1" variant="headingXl">
              {t('under_processing')}
            </Text>
            <BlockStack gap="5">
              <BlockStack gap="2">
                <Text as="p" variant="bodyLg">
                  {t('data_submitted')}
                </Text>
                <Text as="p" variant="bodyLg">
                  {t('view_results_then')}
                </Text>
                <Text as="p" variant="bodyLg">
                  {t('re_sync_if_updated')}
                </Text>
              </BlockStack>
              <InlineStack gap="3" align="start">
                <ButtonGroup>
                  <Button onClick={handleUploadToOrcaHandler} disabled={!isPageActive}>{t('re_sync_data')}</Button>
                  <Button onClick={handleGotoOrca}>{t('orca_data_analysis')}</Button>
                  <Button onClick={handleGotoMa}>{t('orca_marketing_automation')}</Button>
                </ButtonGroup>
              </InlineStack>
            </BlockStack>
          </BlockStack>
        </LegacyCard.Section>
        </div>
      }
      {(projectState === 6) && <div>
        <LegacyCard.Section>
          <BlockStack gap="5">
            <Text as="h1" variant="headingXl">
              {t('analysis_complete')}
            </Text>
            <Text as="p" variant="bodyLg">
              {t('view_results')}
            </Text>
            <Text as="p" variant="bodyLg">
                  {t('re_sync_if_updated')}
                </Text>
            <InlineStack gap="3" align="start">
              <ButtonGroup>
                <Button onClick={handleUploadToOrcaHandler} disabled={!isPageActive}>{t('re_sync_data')}</Button>
                <Button onClick={handleGotoOrca}>{t('orca_data_analysis')}</Button>
                <Button onClick={handleGotoMa}>{t('orca_marketing_automation')}</Button>
              </ButtonGroup>
            </InlineStack>
          </BlockStack>
        </LegacyCard.Section>
        <LegacyCard.Section>
          <BlockStack gap="5">
              <BlockStack gap="2">
                <Text as="p" variant="bodyLg">
                  {t('last_time_analysis')} { lastUpdate + ' '}
                </Text>
                {
                  isPaid &&
                  <Text as="p" variant="bodyLg">
                    {t('manual_execute_if_need')}
                  </Text>
                }
                {
                  !isPaid && trialDayLeft > 0 &&
                  <Text as="p" variant="bodyLg">
                    {t('3_times_quota')}
                  </Text>
                }
                {
                  !isPaid && trialDayLeft <= 0 &&
                  <Text as="p" variant="bodyLg">
                    {t('no_quota')}
                  </Text>
                }
              </BlockStack>
              <InlineStack gap="3" align="start">
                {
                  isPaid &&
                  <Button onClick={handleOrcaAnalyze} disabled={!isPageActive}>{t('manual_analysis')}</Button>
                }
                {
                  !isPaid && trialDayLeft > 0 &&
                  <Button onClick={handleOrcaAnalyze} loading={countSuccessAnalysis === '-'} disabled={ countSuccessAnalysis === '3'}>{t('manual_analysis')} {countSuccessAnalysis}/3</Button>
                }
                {
                  !isPaid && trialDayLeft <= 0 &&
                  <Button onClick={handleOrcaAnalyze} disabled={!isPageActive}>{t('manual_analysis')}</Button>
                }
              </InlineStack>
          </BlockStack>
        </LegacyCard.Section>
        </div>
      }
    </div>
  )
}

Page4.propTypes = {
  handleTabChange: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired,
  trialDayLeft: PropTypes.number.isRequired
}
export default Page4
